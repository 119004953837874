'use strict';

var bind = require('./helpers/bind');

// utils is a library of generic helper functions non-specific to axios

var toString = Object.prototype.toString;

// eslint-disable-next-line func-names
var kindOf = (function(cache) {
 // eslint-disable-next-line func-names
 return function(thing) {
 var str = toString.call(thing);
 return cache[str] || (cache[str] = str.slice(8, -1).toLowerCase());
 };
})(Object.create(null));

function kindOfTest(type) {
 type = type.toLowerCase();
 return function isKindOf(thing) {
 return kindOf(thing) === type;
 };
}

/**
 * Determine if a value is an Array
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Array, otherwise false
 */
function isArray(val) {
 return Array.isArray(val);
}

/**
 * Determine if a value is undefined
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if the value is undefined, otherwise false
 */
function isUndefined(val) {
 return typeof val === 'undefined';
}

/**
 * Determine if a value is a Buffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Buffer, otherwise false
 */
function isBuffer(val) {
 return val !== null && !isUndefined(val) && val.constructor !== null && !isUndefined(val.constructor)
 && typeof val.constructor.isBuffer === 'function' && val.constructor.isBuffer(val);
}

/**
 * Determine if a value is an ArrayBuffer
 *
 * @function
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an ArrayBuffer, otherwise false
 */
var isArrayBuffer = kindOfTest('ArrayBuffer');


/**
 * Determine if a value is a view on an ArrayBuffer
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a view on an ArrayBuffer, otherwise false
 */
function isArrayBufferView(val) {
 var result;
 if ((typeof ArrayBuffer !== 'undefined') && (ArrayBuffer.isView)) {
 result = ArrayBuffer.isView(val);
 } else {
 result = (val) && (val.buffer) && (isArrayBuffer(val.buffer));
 }
 return result;
}

/**
 * Determine if a value is a String
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a String, otherwise false
 */
function isString(val) {
 return typeof val === 'string';
}

/**
 * Determine if a value is a Number
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Number, otherwise false
 */
function isNumber(val) {
 return typeof val === 'number';
}

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
function isObject(val) {
 return val !== null && typeof val === 'object';
}

/**
 * Determine if a value is a plain Object
 *
 * @param {Object} val The value to test
 * @return {boolean} True if value is a plain Object, otherwise false
 */
function isPlainObject(val) {
 if (kindOf(val) !== 'object') {
 return false;
 }

 var prototype = Object.getPrototypeOf(val);
 return prototype === null || prototype === Object.prototype;
}

/**
 * Determine if a value is a Date
 *
 * @function
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Date, otherwise false
 */
var isDate = kindOfTest('Date');

/**
 * Determine if a value is a File
 *
 * @function
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a File, otherwise false
 */
var isFile = kindOfTest('File');

/**
 * Determine if a value is a Blob
 *
 * @function
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Blob, otherwise false
 */
var isBlob = kindOfTest('Blob');

/**
 * Determine if a value is a FileList
 *
 * @function
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a File, otherwise false
 */
var isFileList = kindOfTest('FileList');

/**
 * Determine if a value is a Function
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Function, otherwise false
 */
function isFunction(val) {
 return toString.call(val) === '[object Function]';
}

/**
 * Determine if a value is a Stream
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Stream, otherwise false
 */
function isStream(val) {
 return isObject(val) && isFunction(val.pipe);
}

/**
 * Determine if a value is a FormData
 *
 * @param {Object} thing The value to test
 * @returns {boolean} True if value is an FormData, otherwise false
 */
function isFormData(thing) {
 var pattern = '[object FormData]';
 return thing && (
 (typeof FormData === 'function' && thing instanceof FormData) ||
 toString.call(thing) === pattern ||
 (isFunction(thing.toString) && thing.toString() === pattern)
 );
}

/**
 * Determine if a value is a URLSearchParams object
 * @function
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a URLSearchParams object, otherwise false
 */
var isURLSearchParams = kindOfTest('URLSearchParams');

/**
 * Trim excess whitespace off the beginning and end of a string
 *
 * @param {String} str The String to trim
 * @returns {String} The String freed of excess whitespace
 */
function trim(str) {
 return str.trim ? str.trim() : str.replace(/^\s+|\s+$/g, '');
}

/**
 * Determine if we're running in a standard browser environment
 *
 * This allows axios to run in a web worker, and react-native.
 * Both environments support XMLHttpRequest, but not fully standard globals.
 *
 * web workers:
 * typeof window -> undefined
 * typeof document -> undefined
 *
 * react-native:
 * navigator.product -> 'ReactNative'
 * nativescript
 * navigator.product -> 'NativeScript' or 'NS'
 */
function isStandardBrowserEnv() {
 if (typeof navigator !== 'undefined' && (navigator.product === 'ReactNative' ||
 navigator.product === 'NativeScript' ||
 navigator.product === 'NS')) {
 return false;
 }
 return (
 typeof window !== 'undefined' &&
 typeof document !== 'undefined'
 );
}

/**
 * Iterate over an Array or an Object invoking a function for each item.
 *
 * If `obj` is an Array callback will be called passing
 * the value, index, and complete array for each item.
 *
 * If 'obj' is an Object callback will be called passing
 * the value, key, and complete object for each property.
 *
 * @param {Object|Array} obj The object to iterate
 * @param {Function} fn The callback to invoke for each item
 */
function forEach(obj, fn) {
 // Don't bother if no value provided
 if (obj === null || typeof obj === 'undefined') {
 return;
 }

 // Force an array if not already something iterable
 if (typeof obj !== 'object') {
 /*eslint no-param-reassign:0*/
 obj = [obj];
 }

 if (isArray(obj)) {
 // Iterate over array values
 for (var i = 0, l = obj.length; i < l; i++) {
 fn.call(null, obj[i], i, obj);
 }
 } else {
 // Iterate over object keys
 for (var key in obj) {
 if (Object.prototype.hasOwnProperty.call(obj, key)) {
 fn.call(null, obj[key], key, obj);
 }
 }
 }
}

/**
 * Accepts varargs expecting each argument to be an object, then
 * immutably merges the properties of each object and returns result.
 *
 * When multiple objects contain the same key the later object in
 * the arguments list will take precedence.
 *
 * Example:
 *
 * ```js
 * var result = merge({foo: 123}, {foo: 456});
 * console.log(result.foo); // outputs 456
 * ```
 *
 * @param {Object} obj1 Object to merge
 * @returns {Object} Result of all merge properties
 */
function merge(/* obj1, obj2, obj3, ... */) {
 var result = {};
 function assignValue(val, key) {
 if (isPlainObject(result[key]) && isPlainObject(val)) {
 result[key] = merge(result[key], val);
 } else if (isPlainObject(val)) {
 result[key] = merge({}, val);
 } else if (isArray(val)) {
 result[key] = val.slice();
 } else {
 result[key] = val;
 }
 }

 for (var i = 0, l = arguments.length; i < l; i++) {
 forEach(arguments[i], assignValue);
 }
 return result;
}

/**
 * Extends object a by mutably adding to it the properties of object b.
 *
 * @param {Object} a The object to be extended
 * @param {Object} b The object to copy properties from
 * @param {Object} thisArg The object to bind function to
 * @return {Object} The resulting value of object a
 */
function extend(a, b, thisArg) {
 forEach(b, function assignValue(val, key) {
 if (thisArg && typeof val === 'function') {
 a[key] = bind(val, thisArg);
 } else {
 a[key] = val;
 }
 });
 return a;
}

/**
 * Remove byte order marker. This catches EF BB BF (the UTF-8 BOM)
 *
 * @param {string} content with BOM
 * @return {string} content value without BOM
 */
function stripBOM(content) {
 if (content.charCodeAt(0) === 0xFEFF) {
 content = content.slice(1);
 }
 return content;
}

/**
 * Inherit the prototype methods from one constructor into another
 * @param {function} constructor
 * @param {function} superConstructor
 * @param {object} [props]
 * @param {object} [descriptors]
 */

function inherits(constructor, superConstructor, props, descriptors) {
 constructor.prototype = Object.create(superConstructor.prototype, descriptors);
 constructor.prototype.constructor = constructor;
 props && Object.assign(constructor.prototype, props);
}

/**
 * Resolve object with deep prototype chain to a flat object
 * @param {Object} sourceObj source object
 * @param {Object} [destObj]
 * @param {Function} [filter]
 * @returns {Object}
 */

function toFlatObject(sourceObj, destObj, filter) {
 var props;
 var i;
 var prop;
 var merged = {};

 destObj = destObj || {};

 do {
 props = Object.getOwnPropertyNames(sourceObj);
 i = props.length;
 while (i-- > 0) {
 prop = props[i];
 if (!merged[prop]) {
 destObj[prop] = sourceObj[prop];
 merged[prop] = true;
 }
 }
 sourceObj = Object.getPrototypeOf(sourceObj);
 } while (sourceObj && (!filter || filter(sourceObj, destObj)) && sourceObj !== Object.prototype);

 return destObj;
}

/*
 * determines whether a string ends with the characters of a specified string
 * @param {String} str
 * @param {String} searchString
 * @param {Number} [position= 0]
 * @returns {boolean}
 */
function endsWith(str, searchString, position) {
 str = String(str);
 if (position === undefined || position > str.length) {
 position = str.length;
 }
 position -= searchString.length;
 var lastIndex = str.indexOf(searchString, position);
 return lastIndex !== -1 && lastIndex === position;
}


/**
 * Returns new array from array like object
 * @param {*} [thing]
 * @returns {Array}
 */
function toArray(thing) {
 if (!thing) return null;
 var i = thing.length;
 if (isUndefined(i)) return null;
 var arr = new Array(i);
 while (i-- > 0) {
 arr[i] = thing[i];
 }
 return arr;
}

// eslint-disable-next-line func-names
var isTypedArray = (function(TypedArray) {
 // eslint-disable-next-line func-names
 return function(thing) {
 return TypedArray && thing instanceof TypedArray;
 };
})(typeof Uint8Array !== 'undefined' && Object.getPrototypeOf(Uint8Array));

module.exports = {
 isArray: isArray,
 isArrayBuffer: isArrayBuffer,
 isBuffer: isBuffer,
 isFormData: isFormData,
 isArrayBufferView: isArrayBufferView,
 isString: isString,
 isNumber: isNumber,
 isObject: isObject,
 isPlainObject: isPlainObject,
 isUndefined: isUndefined,
 isDate: isDate,
 isFile: isFile,
 isBlob: isBlob,
 isFunction: isFunction,
 isStream: isStream,
 isURLSearchParams: isURLSearchParams,
 isStandardBrowserEnv: isStandardBrowserEnv,
 forEach: forEach,
 merge: merge,
 extend: extend,
 trim: trim,
 stripBOM: stripBOM,
 inherits: inherits,
 toFlatObject: toFlatObject,
 kindOf: kindOf,
 kindOfTest: kindOfTest,
 endsWith: endsWith,
 toArray: toArray,
 isTypedArray: isTypedArray,
 isFileList: isFileList
};
