'use strict';

var utils = require('../utils');
var normalizeHeaderName = require('../helpers/normalizeHeaderName');
var AxiosError = require('../core/AxiosError');
var transitionalDefaults = require('./transitional');
var toFormData = require('../helpers/toFormData');

var DEFAULT_CONTENT_TYPE = {
 'Content-Type': 'application/x-www-form-urlencoded'
};

function setContentTypeIfUnset(headers, value) {
 if (!utils.isUndefined(headers) && utils.isUndefined(headers['Content-Type'])) {
 headers['Content-Type'] = value;
 }
}

function getDefaultAdapter() {
 var adapter;
 if (typeof XMLHttpRequest !== 'undefined') {
 // For browsers use XHR adapter
 adapter = require('../adapters/xhr');
 } else if (typeof process !== 'undefined' && Object.prototype.toString.call(process) === '[object process]') {
 // For node use HTTP adapter
 adapter = require('../adapters/http');
 }
 return adapter;
}

function stringifySafely(rawValue, parser, encoder) {
 if (utils.isString(rawValue)) {
 try {
 (parser || JSON.parse)(rawValue);
 return utils.trim(rawValue);
 } catch (e) {
 if (e.name !== 'SyntaxError') {
 throw e;
 }
 }
 }

 return (encoder || JSON.stringify)(rawValue);
}

var defaults = {

 transitional: transitionalDefaults,

 adapter: getDefaultAdapter(),

 transformRequest: [function transformRequest(data, headers) {
 normalizeHeaderName(headers, 'Accept');
 normalizeHeaderName(headers, 'Content-Type');

 if (utils.isFormData(data) ||
 utils.isArrayBuffer(data) ||
 utils.isBuffer(data) ||
 utils.isStream(data) ||
 utils.isFile(data) ||
 utils.isBlob(data)
 ) {
 return data;
 }
 if (utils.isArrayBufferView(data)) {
 return data.buffer;
 }
 if (utils.isURLSearchParams(data)) {
 setContentTypeIfUnset(headers, 'application/x-www-form-urlencoded;charset=utf-8');
 return data.toString();
 }

 var isObjectPayload = utils.isObject(data);
 var contentType = headers && headers['Content-Type'];

 var isFileList;

 if ((isFileList = utils.isFileList(data)) || (isObjectPayload && contentType === 'multipart/form-data')) {
 var _FormData = this.env && this.env.FormData;
 return toFormData(isFileList ? {'files[]': data} : data, _FormData && new _FormData());
 } else if (isObjectPayload || contentType === 'application/json') {
 setContentTypeIfUnset(headers, 'application/json');
 return stringifySafely(data);
 }

 return data;
 }],

 transformResponse: [function transformResponse(data) {
 var transitional = this.transitional || defaults.transitional;
 var silentJSONParsing = transitional && transitional.silentJSONParsing;
 var forcedJSONParsing = transitional && transitional.forcedJSONParsing;
 var strictJSONParsing = !silentJSONParsing && this.responseType === 'json';

 if (strictJSONParsing || (forcedJSONParsing && utils.isString(data) && data.length)) {
 try {
 return JSON.parse(data);
 } catch (e) {
 if (strictJSONParsing) {
 if (e.name === 'SyntaxError') {
 throw AxiosError.from(e, AxiosError.ERR_BAD_RESPONSE, this, null, this.response);
 }
 throw e;
 }
 }
 }

 return data;
 }],

 /**
 * A timeout in milliseconds to abort a request. If set to 0 (default) a
 * timeout is not created.
 */
 timeout: 0,

 xsrfCookieName: 'XSRF-TOKEN',
 xsrfHeaderName: 'X-XSRF-TOKEN',

 maxContentLength: -1,
 maxBodyLength: -1,

 env: {
 FormData: require('./env/FormData')
 },

 validateStatus: function validateStatus(status) {
 return status >= 200 && status < 300;
 },

 headers: {
 common: {
 'Accept': 'application/json, text/plain, */*'
 }
 }
};

utils.forEach(['delete', 'get', 'head'], function forEachMethodNoData(method) {
 defaults.headers[method] = {};
});

utils.forEach(['post', 'put', 'patch'], function forEachMethodWithData(method) {
 defaults.headers[method] = utils.merge(DEFAULT_CONTENT_TYPE);
});

module.exports = defaults;
