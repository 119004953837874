'use strict';

var AxiosError = require('./AxiosError');

/**
 * Resolve or reject a Promise based on response status.
 *
 * @param {Function} resolve A function that resolves the promise.
 * @param {Function} reject A function that rejects the promise.
 * @param {object} response The response.
 */
module.exports = function settle(resolve, reject, response) {
 var validateStatus = response.config.validateStatus;
 if (!response.status || !validateStatus || validateStatus(response.status)) {
 resolve(response);
 } else {
 reject(new AxiosError(
 'Request failed with status code ' + response.status,
 [AxiosError.ERR_BAD_REQUEST, AxiosError.ERR_BAD_RESPONSE][Math.floor(response.status / 100) - 4],
 response.config,
 response.request,
 response
 ));
 }
};
