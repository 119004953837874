'use strict';

var utils = require('./../utils');
var buildURL = require('../helpers/buildURL');
var InterceptorManager = require('./InterceptorManager');
var dispatchRequest = require('./dispatchRequest');
var mergeConfig = require('./mergeConfig');
var buildFullPath = require('./buildFullPath');
var validator = require('../helpers/validator');

var validators = validator.validators;
/**
 * Create a new instance of Axios
 *
 * @param {Object} instanceConfig The default config for the instance
 */
function Axios(instanceConfig) {
 this.defaults = instanceConfig;
 this.interceptors = {
 request: new InterceptorManager(),
 response: new InterceptorManager()
 };
}

/**
 * Dispatch a request
 *
 * @param {Object} config The config specific for this request (merged with this.defaults)
 */
Axios.prototype.request = function request(configOrUrl, config) {
 /*eslint no-param-reassign:0*/
 // Allow for axios('example/url'[, config]) a la fetch API
 if (typeof configOrUrl === 'string') {
 config = config || {};
 config.url = configOrUrl;
 } else {
 config = configOrUrl || {};
 }

 config = mergeConfig(this.defaults, config);

 // Set config.method
 if (config.method) {
 config.method = config.method.toLowerCase();
 } else if (this.defaults.method) {
 config.method = this.defaults.method.toLowerCase();
 } else {
 config.method = 'get';
 }

 var transitional = config.transitional;

 if (transitional !== undefined) {
 validator.assertOptions(transitional, {
 silentJSONParsing: validators.transitional(validators.boolean),
 forcedJSONParsing: validators.transitional(validators.boolean),
 clarifyTimeoutError: validators.transitional(validators.boolean)
 }, false);
 }

 // filter out skipped interceptors
 var requestInterceptorChain = [];
 var synchronousRequestInterceptors = true;
 this.interceptors.request.forEach(function unshiftRequestInterceptors(interceptor) {
 if (typeof interceptor.runWhen === 'function' && interceptor.runWhen(config) === false) {
 return;
 }

 synchronousRequestInterceptors = synchronousRequestInterceptors && interceptor.synchronous;

 requestInterceptorChain.unshift(interceptor.fulfilled, interceptor.rejected);
 });

 var responseInterceptorChain = [];
 this.interceptors.response.forEach(function pushResponseInterceptors(interceptor) {
 responseInterceptorChain.push(interceptor.fulfilled, interceptor.rejected);
 });

 var promise;

 if (!synchronousRequestInterceptors) {
 var chain = [dispatchRequest, undefined];

 Array.prototype.unshift.apply(chain, requestInterceptorChain);
 chain = chain.concat(responseInterceptorChain);

 promise = Promise.resolve(config);
 while (chain.length) {
 promise = promise.then(chain.shift(), chain.shift());
 }

 return promise;
 }


 var newConfig = config;
 while (requestInterceptorChain.length) {
 var onFulfilled = requestInterceptorChain.shift();
 var onRejected = requestInterceptorChain.shift();
 try {
 newConfig = onFulfilled(newConfig);
 } catch (error) {
 onRejected(error);
 break;
 }
 }

 try {
 promise = dispatchRequest(newConfig);
 } catch (error) {
 return Promise.reject(error);
 }

 while (responseInterceptorChain.length) {
 promise = promise.then(responseInterceptorChain.shift(), responseInterceptorChain.shift());
 }

 return promise;
};

Axios.prototype.getUri = function getUri(config) {
 config = mergeConfig(this.defaults, config);
 var fullPath = buildFullPath(config.baseURL, config.url);
 return buildURL(fullPath, config.params, config.paramsSerializer);
};

// Provide aliases for supported request methods
utils.forEach(['delete', 'get', 'head', 'options'], function forEachMethodNoData(method) {
 /*eslint func-names:0*/
 Axios.prototype[method] = function(url, config) {
 return this.request(mergeConfig(config || {}, {
 method: method,
 url: url,
 data: (config || {}).data
 }));
 };
});

utils.forEach(['post', 'put', 'patch'], function forEachMethodWithData(method) {
 /*eslint func-names:0*/

 function generateHTTPMethod(isForm) {
 return function httpMethod(url, data, config) {
 return this.request(mergeConfig(config || {}, {
 method: method,
 headers: isForm ? {
 'Content-Type': 'multipart/form-data'
 } : {},
 url: url,
 data: data
 }));
 };
 }

 Axios.prototype[method] = generateHTTPMethod();

 Axios.prototype[method + 'Form'] = generateHTTPMethod(true);
});

module.exports = Axios;
